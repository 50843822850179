'use strict';

(function($){
	let body = $('body');
  let collapsePlanos = document.getElementById("collapse-planos")
  let collapseGarajes = document.getElementById("collapse-garajes")
  let app = {

    init: function() {
      this.cookiesManagement();
      this.menu();
      this.lazyframe();
      bsCustomFileInput.init()
      this.swiper();
      this.projectFilters();
      this.headquartersMap();
      this.newsletterPopup();
      this.tableScroll(collapsePlanos, collapseGarajes);
      this.modalDownload();
      OverlayScrollbars(document.querySelectorAll('.scrollable'), {
        overflowBehavior: {
            x: "scroll"
        }
      });
      this.inscriptionUnitiesManagement();
      this.goToCurriculumSend();
      return false;
    },
    menu: function() {
        let btn = $('.btn-burguer')
        btn.click(function(){
            body.toggleClass('menu--open')
        })
    },
    cookiesManagement : function () {
        //Lanzamiento de modal cookies si no estan definidas
        let modalCookies = $('#modal-cookies'),
            buttonAccept = $('#modal-cookies').find('.btn-cookies-accept'),
            buttonRefuse = $('#modal-cookies').find('#cookie-btn-refuse'),
            buttonConfigure = $('#modal-cookies').find('.btn-cookies-configure'),
            typesCookies = ['analiticas','marketing','rrss'];
        if(($.cookie(keyAnalyticCookies) === undefined || $.cookie(keyMarketingCookies) === undefined || $.cookie(keyRrssCookies) === undefined) && !$('body').hasClass('body-cookies-policy')) {
            modalCookies.modal();
        }

        buttonAccept.click(function(){
            for (let d in  typesCookies) {
                if(parseInt(d) !== typesCookies.length -1 ) app.setChecked(typesCookies[d]);
                else app.setChecked(typesCookies[d]).then(app.saveCookiesConfigure(modalCookies));
            }
        })

        buttonRefuse.click(function(){
            for (let d in  typesCookies) {
                if(parseInt(d) !== typesCookies.length -1 ) app.setUnchecked(typesCookies[d]);
                else app.setUnchecked(typesCookies[d]).then(app.saveCookiesConfigure(modalCookies));
            }
        })

        buttonConfigure.click(function(){
            app.saveCookiesConfigure(modalCookies);
        })
    },
    setChecked : function (id) {
        return new Promise(function (resolve) {
            $('input#' + id).prop('checked',true);
            resolve();
        })
    },
      setUnchecked : function (id) {
        return new Promise(function (resolve) {
            $('input#' + id).prop('checked',false);
            resolve();
        })
    },
    saveCookiesConfigure : function (modalCookies) {
        let checkAnalytics = modalCookies.find('#analiticas'),
            checkMarketing = modalCookies.find('#marketing'),
            checkRrss = modalCookies.find('#rrss');
        $.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: ((checkAnalytics.prop('checked')) ? 365 : 7), path: '/' });
        $.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: ((checkMarketing.prop('checked')) ? 365 : 7), path: '/' });
        $.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: ((checkRrss.prop('checked')) ? 365 : 7), path: '/' });
        $.cookie('ccdt', new Date().getTime(), { expires: 365, path: '/' });

        modalCookies.modal('hide');
        location.reload();
    },
    lazyframe: function () {
      lazyframe('.lazyframe', {
        apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
        debounce: 250
      });
    },
    swiper: function() {
        const swiperHome = new Swiper('.swiper-home', {
          autoplay: {
            delay: 8500,
          },
          effect: "fade",
          fadeEffect: {
            crossFade: true
          },
          loop: true,
          pagination: {
              clickable: true,
              el: '.swiper-pagination',
              type: 'bullets',
          },
          resistanceRatio: 0,
          slidesPerView: 1,
          speed: 1000,
          on : {
              init : function (slide) {
                app.controlH1Home(slide);
              },
              slideChange : function (slide) {
                app.controlH1Home(slide);
              }
          }
        });        
        const swiper = new Swiper('.promo-gallery', {
            slidesPerView: 1,
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: ".promo-pagination",
                type: "fraction",
            },
            breakpoints: {
                481: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20
                }
            }
        });
        const swiperNew = new Swiper('.swiper-new', {
            slidesPerView: 1,
            spaceBetween: 15,
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: ".swiper-new__pagination",
                type: "fraction",
            },
        });
        const swiperNewsInterest = new Swiper('.swiper-interested', {
            slidesPerView: 1.1,
            spaceBetween: 15,
            loop: false,
            breakpoints: {
                481: {
                    slidesPerView: 2.1
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        });
    },
    controlH1Home : function (slider) {
        let activeSlide = slider.slides[slider.activeIndex],
            h1Lema = $('.masthead--home__title');

        if($(activeSlide).hasClass('hide-h1')) {
            h1Lema.removeClass('d-inline-block');
            h1Lema.removeClass('d-flex');
            h1Lema.addClass('d-none');
        } else {
            h1Lema.addClass('d-flex');
            h1Lema.removeClass('d-none');
        }
    },
    filterCities :function (table,category,callback)   {
        let ulCities = $('.promo-filter').find('#ciudad'),
            filterProvinces = $('.promo-filter').find('#provincia').find('li'),
            filterCities = ulCities.find('li'),
            provinces = [],
            actuals = [];
        filterProvinces.each(function (){
            if($(this).hasClass('selected')) {
                provinces.push(parseInt($(this).data('id')));
            }
        })
        if(provinces.length <= 0) {
            //Si no hay ninguno seleccionado cogemos todas
            filterProvinces.each(function (){
                provinces.push(parseInt($(this).data('id')));
            })
        }
        filterCities.each(function (){
            if($(this).hasClass('selected')) {
                actuals.push(parseInt($(this).data('id')));
            }
        })
        $.ajax(
            {
                url: site_url + '/citiesByProvinces',
                method: 'post',
                dataType : 'json',
                data: {provinces : provinces, table : table, category : category},
                success: function (data) {
                    ulCities.empty();
                    if(!data.noData) {
                        for(let d in data) {
                            ulCities.append(`<li data-id="${data[d].id}"${(actuals && $.inArray(parseInt(data[d].id),actuals) !== -1) ? ' class="selected"' : ''}>${data[d].ciudad_l}</li>`);
                        }
                    }
                    //Reactivamos click
                    ulCities.find('li').off('click').click(function (){
                        app.treatFilters($(this),table,category);
                    })

                    if(typeof callback === "function") {
                        callback();
                    }
                }
            }
        )
    },
    projectFilters : function () {
        let filtersContainer = $('.promo-filter'),
            filters = filtersContainer.find('ul').find('li'),
            table = filtersContainer.parent().data('target'),
            category = filtersContainer.parent().data('category');
        filters.click(function() {
            app.treatFilters($(this),table,category);
        })

        const filterBtn = $('.btn-filters'),
        filtersDiv = $('.promo-filters');
        filterBtn.click(function(){
            filtersDiv.toggleClass('active')
        })
    },
    treatFilters : function (obj,table,category) {
        let filterFor = obj.parent('ul').attr('id');
        if(obj.hasClass('selected')) obj.removeClass('selected');
        else obj.addClass('selected');
        if(filterFor === 'provincia') app.filterCities(table,category,function (){app.doFilters(table,category)});
        else app.doFilters(table,category);
    },
    doFilters: function (table,category) {
        let projectList = $('.list-product'),
            promoFilter = $('.promo-filter'),
            provinces = [],
            cities = [],
            types = [];
        //Preparamos datos para pasar
        promoFilter.find('#provincia').find('li').each(function (){
            if($(this).hasClass('selected')) {
                provinces.push(parseInt($(this).data('id')));
            }
        });
        promoFilter.find('#ciudad').find('li').each(function (){
            if($(this).hasClass('selected')) {
                cities.push(parseInt($(this).data('id')));
            }
        });
        promoFilter.find('#tipo').find('li').each(function (){
            if($(this).hasClass('selected')) {
                types.push(parseInt($(this).data('id')));
            }
        });
        $.ajax(
            {
                url: site_url + '/projectsByFilters',
                method: 'post',
                dataType : 'json',
                data: {provinces : provinces, cities: cities, types: types, table : table, category : category},
                success: function (data) {
                    projectList.empty();
                    if(!data.noData) {
                        let html = '';
                        for (let d in data) {
                            html +=
                                `<li class="col-12 col-sm-6 col-xl-4">
                                    <a href="${data[d].url}" title="${data[d].titulo_l}" class="productlist d-flex flex-wrap text-white">`;
                            if((data[d].imagen !== '' && data[d].imagen !== null) || (data[d].imagen_listado !== '' && data[d].imagen_listado !== null)){
                                html +=
                                    `        <picture>
                                                <source srcset="${base_url}/media/uploads/${data[d].pathImage}/listado_/${((data[d].imagen_listado !== '' && data[d].imagen_listado !== null) ? data[d].imagen_listado : data[d].imagen)}" media="(min-width: 768px)">
                                                <img src="${base_url}/media/uploads/${data[d].pathImage}/listado_/${((data[d].imagen_listado !== '' && data[d].imagen_listado !== null) ? data[d].imagen_listado : data[d].imagen)}" alt="${data[d].alt_l}" class="img-fluid bg-md-img">
                                             </picture>`;
                            }
                            html +=
                                `
                                        <span class="productlist__content d-flex flex-column w-100">
                                            <span class="d-flex flex-column flex-grow-1 align-items-md-center justify-content-md-center">
                                                <span class="productlist__title lh-1 fs-18 fs-md-40 fw-500">${data[d].ciudad}</span>
                                                <span class="productlist__subtitle text-uppercase lh-1 fs-12 fs-md-20 fw-300 ls-100">${data[d].titulo_l}</span>
                                            </span>
                                        </span>
                                    </a>
                                </li>`;
                        }
                        projectList.html(html);
                    }
                }
            }
        )
    },
    headquartersMap : function () {
       let listHeadquarters = $('.list-contact');
       let headquarters = listHeadquarters.find('li');
       headquarters.click(function () {
           if(typeof window.map !== 'undefined' && typeof window.marker !== 'undefined' && typeof $(this).data('lat') !== 'undefined' && typeof $(this).data('lng') !== 'undefined' && typeof $(this).data('zoom') !== 'undefined') {
               headquarters.removeClass('active');
               $(this).addClass('active');
               window.map.setCenter({ lat: $(this).data('lat'), lng: $(this).data('lng') });
               window.map.setZoom($(this).data('zoom'));
               window.marker.setPosition({ lat: $(this).data('lat'), lng: $(this).data('lng') });
           }
       })
    },
    newsletterPopup : function () {
        let buttonNewsletter = $('#button-newsletter');
        let modalNewsletter = $('#newsletter-modal');
        let closeModalNewsletter = modalNewsletter.find('.news-modal__close');
        buttonNewsletter.click(function(e) {
            e.preventDefault();
            modalNewsletter.toggleClass('d-none');
        })
        closeModalNewsletter.click(function (e){
            e.preventDefault();
            modalNewsletter.addClass('d-none');
        })
    },
    tableScroll: function (...args) {

      function tableScrollState(el) {
        $(el).on('shown.bs.collapse', function () {
          let scrollIndicator = this.querySelector(".scroll-indicator")
          let tableResponsive = this.querySelector(".table-responsive")
          if( tableResponsive.scrollWidth > tableResponsive.clientWidth ) {
            scrollIndicator.classList.add('active')
            tableResponsive.onscroll = function() {
              if ( this.scrollLeft > 0 ) {
                scrollIndicator.classList.remove('active')
              } else {
                scrollIndicator.classList.add('active')
              }
            }
          } else {
            scrollIndicator.classList.remove('active')
          }
        })
      }

      for ( const arg of args) {
        if(arg !== null) {
          tableScrollState(arg)
        }
      }

    },
    inscriptionUnitiesManagement : function () {
        let buttonAddUnity = $('#add-unity'),
            inputNumberUnities = $('#number_unities'),
            baseUnity = $('.unity-base'),
            unitiesContainer = $('#unities_container'),
            item = 0;
        buttonAddUnity.click(function (){
            item = parseInt(inputNumberUnities.val()) + 1;
            let cloned = baseUnity.clone();
            cloned.html(cloned.html().replaceAll('__ITEM__',item).replaceAll('__BASE__',''));
            cloned.attr('id',cloned.attr('id').replace('__ITEM__',item));
            cloned.removeClass('unity-base');
            unitiesContainer.append(cloned.get(0).outerHTML);
            inputNumberUnities.val(item);
            $(`.del-unity[data-item="${item}"]`).click(function (){
                app.deleteUnity($(this),inputNumberUnities,unitiesContainer)
            })
            unitiesContainer.find('[id^="unity_"]').each(function(){
                if($(this).attr('id') !== `unity_${item}`){
                    $(this).find('.del-unity').removeClass('d-inline-block').addClass('d-none');
                }
            })
        })
    },
    deleteUnity : function(obj,inputNumberUnities,unitiesContainer) {
        let itemToDel = obj.data('item'),
            divToDel = $(`#unity_${itemToDel}`);

        inputNumberUnities.val(parseInt(inputNumberUnities.val()) - 1);
        divToDel.slideUp().remove();
        unitiesContainer.find('[id^="unity_"]').each(function(){
            if($(this).attr('id') !== `unity_${inputNumberUnities.val()}`){
                $(this).find('.del-unity').removeClass('d-inline-block').addClass('d-none');
            } else {
                $(this).find('.del-unity').removeClass('d-none').addClass('d-inline-block');
            }
        })
    },
    goToCurriculumSend : function () {
        let container = $('#accordionOffers'),
            btnGo = container.find('.btn-offer'),
            form = $('#form-trabaja');

        btnGo.click(function (){
            let offer = $(this).data('id'),
                job = $(this).data('job');

            form.find('[name="idoferta"]').val(offer);
            form.find('#puesto').val(job);

            $('html,body').animate(
                {
                    scrollTop : form.offset().top
                }, 1000
            )
        })
    },
    modalDownload : function () {
      let caller = $('[data-toggle="modal"].btn-modal-download');
      caller.click(function (e){
         let target = $(this).data('target');
         let document = $(this).data('document');
         $(target).find('[name="archivo"]').val(document);
      })
    },
  };
  $(function(){
    app.init()
  });
})(jQuery);
